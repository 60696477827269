import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const RecipePage = () => (
    <Layout metaTitle="Bacon & Ale Skillet Braised Chicken Thighs"
        metaDescription="This recipe combines Wright® Brand Applewood Smoked Bacon with your favorite beer and juicy chicken thighs to create an amazing dinner. Try it out for yourself."
        metaKeywords=""
    >
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/brazedchicken_large.jpg'} class='img-responsive' alt='Bacon and Ale Braised Chicken' />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon & Ale Skillet Braised Chicken Thighs</h1>

                            <div class="recipe-content">
                                <p >There are two fundamental truths around our smokehouse: 1. Over the last 90 years we&rsquo;ve mastered the craft of making bacon, and 2. Everything is better with beer.&nbsp; This recipe combines the two then adds tender, juicy chicken thighs. Honestly, there&rsquo;s not much more to say here.&nbsp;</p>
                                <p>Enjoy.</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        40&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        1&nbsp;hour                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>6-8 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                            12 <span class="uom">Slices</span> Wright&reg; Brand Applewood Smoked Bacon, Cut Into ½” Strips    
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                            8 <span class="uom"></span> chicken thighs, Bone-In, Skin-On
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Pound</span> pearl onions, red and/or white, peeled
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom">Ounces</span> malt vinegar
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 <span class="uom"></span> bottles of a medium bodied flavorful microbrew (like an IPA)
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Tablespoon</span> finely chopped thyme
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 <span class="uom">Teaspoon</span> teaspoon parsley, finely chopped
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                <span class="uom"></span> salt and finely ground pepper to taste
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. First remove the chicken thighs from their packaging and place them on a cookie sheet. Give them a good pat on both sides with a paper towel to dry. Then, season both sides with salt and black pepper and leave them skin side up until you’re ready to cook.
                </p>
                            <p>
                                2. Next place the cut bacon into a large (preferably cast iron) skillet. Set the skillet over medium heat and cook the bacon to a nice crispy brown.  Then, remove the cooked bacon with a slotted spoon, and reserve.
                </p>
                            <p>
                                3. Now, place your chicken thighs, skin side down, into the skillet.  Cook them over medium heat until the skin is golden brown, (approximately 7 minutes).
                </p>
                            <p>
                                4. Next flip the chicken and cook it meat side to give the other side a golden brown, (approximately 5 more minutes).
                </p>
                            <p>
                                5. Now remove the browned chicken from the pan and reserve on a plate or cookie sheet.
                </p>
                            <p>
                                6. Then add your peeled pearl onions to the skillet and brown them gently on all sides, (approximately 10 minutes).
                </p>
                            <p>
                                7. Next drain off the excess fat from your skillet.  Then add malt vinegar, and reduce by half (approximately 1 minute or less).  Be sure to use a wooden spoon, rubber spatula, or flat whisk to scrape the browned bits from the bottom of your pan.
                </p>
                            <p>
                                8. Now comes the beer. Add both bottles to the skillet and bring them to a boil. Again, use a wooden spoon, rubber spatula, or flat whisk to scrape the browned bits from your pan.
                </p>
                            <p>
                                9. Next add the crispy bacon, chicken (skin side up), and 2 tsp of chopped thyme back into your skillet.  Then reduce the heat to a simmer and cover, but leave the lid slightly askew to allow steam to escape. Continue to cook at a light simmer until the chicken thighs are cooked through, (20-25 minutes as the cooked chicken must reach 180°F on a meat thermometer for this method).
                </p>
                            <p>
                                10. After the chicken is cooked, remove the lid, and serve two thighs per friend with a generous ladle of bacon, onions, and the beer braising juices. Consider complementing your dish with potatoes, polenta, or Bacon-Hazelnut Farro salad and sprinkle with remaining thyme and parsley.
                </p>
                            <p>
                                11. Chef Tips – If you are worried about the beer flavor being too strong, substitute 12 oz. low/no sodium chicken broth for 12 oz. (1 bottle) beer.

            That’s how you cook chicken thighs, the Wright Way.

                </p>
                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
